<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header class="sticky-header">
      <b-badge
        variant="warning"
        class="font-small-4 px-2 py-75"
      >
        DOANH THU CÁC ĐỘI THÁNG {{ filter.month }}/{{ filter.year }}
      </b-badge>
      <div class="d-flex align-items-center">
        <v-select
          :value="filter.month"
          class="select-size-sm mr-25"
          :options="$monthOptions"
          :searchable="false"
          :clearable="true"
          @input="(val) => $emit('update:filter', Object.assign(filter, {month: val}))"
        />
        <v-select
          :value="filter.year"
          class="select-size-sm"
          label="name"
          :options="$yearOptions"
          :searchable="false"
          :clearable="true"
          @input="(val) => $emit('update:filter', Object.assign(filter, {year: val}))"
        />
      </div>
    </b-card-header>
    <b-card-body>
      <vue-apex-charts
        class="chart-total-amount-team-month"
        type="bar"
        height="400"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge, BCard, BCardBody, BCardHeader, VBTooltip,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import vSelect from 'vue-select'


export default {
  components: {
    VueApexCharts,
    BBadge,
    BCardBody,
    BCardHeader,
    BCard,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    sumDepartment: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    sortSum() {
      const { sumDepartment } = this
      return sumDepartment.sort((a, b) => {
        if (b.total_amount_for_team !== a.total_amount_for_team) return b.total_amount_for_team - a.total_amount_for_team
        return a.department_id - b.department_id
      })
    },
    sortDepartmentById() {
      const { sumDepartment } = this
      return sumDepartment.sort((a, b) => a.department_id - b.department_id).map((item, index) => ({
        ...item,
        index,
      })).reduce((acc, item) => {
        acc[item.department_id] = item.index
        return acc
      }, {})
    },

    dataSeries() {
      return this.sortSum.map(item => item.total_amount_for_team)
    },
    colors() {
      const sortSum = JSON.parse(JSON.stringify(this.sortSum))
      return sortSum.map(item => this.$colorsChart[this.sortDepartmentById[item.department_id]])
    },
    series() {
      return [{
        data: this.dataSeries,
      }]
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 400,
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,

          },
        },
        colors: this.colors,
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#000'],
          },
          formatter(val, opt) {
            return `${opt.w.globals.labels[opt.dataPointIndex]}`
          },
          offsetX: 50,
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        xaxis: {
          categories: this.sortSum.map(item => item.department_name),
        },
        yaxis: {
          labels: {
            show: false,
          },
        },

        tooltip: {
          theme: 'dark',
          x: {
            show: false,
          },
          y: {
            title: {
              formatter() {
                return ''
              },
            },
          },
        },
      }
    },
  },
}
</script>

  <style lang="scss">
  .chart-total-amount-team-month {
    .apexcharts-yaxis-inversed,
    .apexcharts-toolbar {
      display: none;
    }
    .apexcharts-bar-area {
      pointer-events: none;
    }
    .apexcharts-data-labels {
      display: none;
    }
  }
  .d-grid--time-report-deparment {
    display: grid;
    grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    gap: 8px;
    .w-100px {
      width: 100px;
    }
    .vs--disabled {
      .vs__dropdown-toggle {
        background-color: #fff;
      }
    }
  }
  </style>
