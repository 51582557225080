<template>
  <b-card
    no-body
    class="custom-border-top-1px"
  >
    <b-card-header class="sticky-header">
      <b-badge
        variant="success"
        class="font-small-4 px-2 py-75"
      >
        HỒ SƠ
      </b-badge>
      <div class="d-grid--time-report-deparment">
        <div class="d-flex align-items-center">
          <span class="mr-50 w-100px">Từ đầu {{ timeFilter.monthStart ? 'tháng' : 'năm' }}</span>
          <v-select
            :value="timeFilter.monthStart"
            class="select-size-sm mr-25"
            label="name"
            :options="$monthOptions"
            :searchable="false"
            :clearable="true"
            @input="(val) => $emit('update:timeFilter', Object.assign(timeFilter, {monthStart: val}))"
          />
          <v-select
            :value="timeFilter.yearStart"
            class="select-size-sm"
            label="name"
            :options="$yearOptions"
            :searchable="false"
            :clearable="false"
            @input="(val) => $emit('update:timeFilter', Object.assign(timeFilter, {yearStart: val}))"
          />
        </div>
        <div class="d-flex align-items-center">
          <span class="mr-50 w-100px">Đến cuối {{ timeFilter.monthEnd ? 'tháng' : 'năm' }}</span>
          <v-select
            :value="timeFilter.monthEnd"
            class="select-size-sm mr-25"
            label="name"
            :options="$monthOptions"
            :searchable="false"
            :clearable="true"
            @input="(val) => $emit('update:timeFilter', Object.assign(timeFilter, {monthEnd: val}))"
          />
          <v-select
            :value="timeFilter.yearEnd"
            class="select-size-sm"
            label="name"
            :options="$yearOptions"
            :searchable="false"
            :clearable="false"
            @input="(val) => $emit('update:timeFilter', Object.assign(timeFilter, {yearEnd: val}))"
          />
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="12"
          lg="8"
        >
          <div class="border border-radius-4 flex-grow-1 shadow">
            <div class="p-2 border-bottom">
              <div class="w-100 font-weight-bolder font-medium-1 d-flex flex-wrap">
                <div class="mb-50 flex-grow-1">
                  <span class="d-inline-block mr-1">Tổng số:</span>
                  <span
                    class="text-primary"
                  >{{ sumData.total_count }}
                    <span
                      class="font-weight-normal"
                    >(<span
                      class="text-success"
                    >{{ sumData.total_count - sumData.contract_total_count }} HS</span>&nbsp;<small>& </small>
                      <span class="text-danger">{{ sumData.contract_total_count }} HĐ</span>)</span>
                  </span>
                </div>
                <div class="flex-grow-1">
                  <span class="d-inline-block mr-1">Tổng giá trị HS:</span>
                  <span
                    class="text-primary"
                  >{{ formatNumber(sumData.original_total) }} đ</span>
                </div>
              </div>
            </div>
            <div class="contract-report-wrap ">
              <div
                v-if="namesWithMosteVote.length"
                class="d-flex ga-4 mb-2"
              >
                <b class="sl-16">Nhân viên tiêu biểu tháng {{ beforeMonthText }}:  </b>
                <b class="sl-16 text-primary">{{ customJoin(namesWithMosteVote) }}</b>
              </div>
              <b-row class="w-100">
                <b-col
                  cols="12"
                  md="6"
                >
                  <div
                    v-for="(c, idx) in hosoByStatus"
                    :key="idx"
                    class="mb-75 cursor-pointer"
                    @click="goContractsList({typeDateFilter: 'default', statusFilter: c.value})"
                  >
                    <strong
                      class="minw-30px d-inline-block"
                      :class="'text-' + c.value"
                    >{{
                      c.count
                    }}</strong>
                    <b-badge
                      class="font-small-3"
                      :class="'bg-light-' + c.value + ' text-' + c.value"
                    >
                      {{ $status.getName(c.value) }}
                    </b-badge>
                  </div>
                  <div
                    v-for="(c, idx) in hopdongByStatus"
                    :key="idx + '_' + 'hd_status'"
                    class="mb-75 cursor-pointer"
                    @click="goContractsList({typeDateFilter: 'default', statusFilter: c.value})"
                  >
                    <strong
                      class="minw-30px d-inline-block"
                      :class="'text-' + c.value"
                    >{{
                      c.count
                    }}</strong>
                    <b-badge
                      class="font-small-3"
                      :class="'bg-light-' + c.value + ' text-' + c.value"
                    >
                      {{ $status.getName(c.value) }}
                    </b-badge>
                  </div>
                  <div
                    class="mb-75 cursor-pointer"
                    @click="goContractsList({typeDateFilter: 'from_collect_at', hasCollectedFilter: 'true'})"
                  >
                    <strong
                      class="minw-30px d-inline-block"
                      :class="'text-' + customFieldReport[0].value"
                    >{{
                      customFieldReport[0].value
                    }}</strong>
                    <b-badge
                      class="font-small-3"
                      :class="'bg-light-' + customFieldReport[0].value + ' text-' + customFieldReport[0].value"
                    >
                      {{ customFieldReport[0].label }}
                    </b-badge>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <div
                    v-for="(field, index) in customFieldReport.slice(1)"
                    :key="index + '_' + 'custom-field'"
                    class="custom-field cursor-pointer"
                    @click="openModalDebtDetail"
                  >
                    <div class="custom-field--label">
                      {{ field.label }}:
                    </div>
                    <div class="custom-field--value text-primary">
                      {{ field.value }}
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="4"
        >
          <vue-apex-charts
            type="donut"
            height="280"
            width="280"
            :options="chartData.chartOptions"
            :series="chartData.series"
          />
        </b-col>
      </b-row>
    </b-card-body>
    <b-modal
      ref="modal-debt-detail"
      ok-title="Ok"
      ok-only
      size="xl"
      centered
      header-text-variant="primary"
      title="Chi tiết thu nợ"
    >
      <b-card-text>
        <b-table
          ref="refUsersListTable"
          class="position-relative"
          responsive
          hover
          small
          bordered
          primary-key="id"
          show-empty
          empty-text="Không có kết quả nào"
          :items="debtCollectionReportList"
          :fields="tableColumnsDebtCollectionReport"
        >
          <template #cell(code)="data">
            <span class="text-primary text-nowrap">
              {{ data.item.contract.contract_code || data.item.contract.document_code }}
            </span>
          </template>
          <template #cell(doi)="data">
            <span class="text-primary text-nowrap">{{ data.item.department && data.item.department.name }}</span>
          </template>
          <template #cell(ngay-thu)="data">
            <span class="text-nowrap">{{ formatDate(data.item.collect_at) }}</span>
          </template>
          <template #cell(thu-duoc)="data">
            <span class="text-primary text-nowrap">{{ formatNumber(data.item.amount) }} đ</span>
          </template>
          <template #cell(chi-doi)="data">
            <span class="text-primary text-nowrap">{{ formatNumber(data.item.amount_for_team) }} đ</span>
          </template>
          <template #cell(loai-no)="data">
            <span class="text-nowrap">{{ data.item.debt_type === 'origin' ? 'Nợ gốc' : 'Nợ lãi' }}</span>
          </template>
          <template #cell(phi-dv)="data">
            <span class="text-nowrap">{{ formatNumber(parseFloat(data.item.fee_to_buy_original || 0)) }} %</span>
          </template>
          <template #cell(tong-pdv)="data">
            <span class="text-primary text-nowrap">{{ formatNumber(parseFloat(data.item.amount_to_buy_original || 0)) }} đ</span>
          </template>
        </b-table>
        <br>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BRow,
  VBTooltip,
  BModal,
  BCardText,
  BTable,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import vSelect from 'vue-select'
import moment from 'moment'
import http from '@/global/http'
import { getBeforeMonth } from '@/utils/helpers'

export default {
  components: {
    VueApexCharts,
    BRow,
    BCol,
    BBadge,
    BCardBody,
    BCardHeader,
    BCard,
    BModal,
    BCardText,
    BTable,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    departmentId: {
      type: Number,
      default: null,
    },
    data: {
      type: Object,
      default: () => {},
    },
    sumData: {
      type: Object,
      default: () => {},
    },
    sumDataChart: {
      type: Object,
      default: () => {},
    },
    reportDepartmentHS: {
      type: Object,
      default: () => {},
    },
    reportDepartmentHD: {
      type: Object,
      default: () => {},
    },
    filter: {
      type: Object,
      default: () => {},
    },
    timeFilter: {
      type: Object,
      default: () => {},
    },
    customFieldReport: {
      type: Array,
      default: () => [],
    },
    namesWithMosteVote: {
      type: Array,
      default: () => [],
    },
    debtCollectionReportList: {
      type: Array,
      default: () => [],
    },
    fetchDebtCollectionDetailReport: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      monthDateConfig: {
        altInput: true,
        allowInvalidPreload: true,
        allowInput: false,
        plugins: [
          monthSelectPlugin({
            shorthand: true,
            dateFormat: 'm-Y',
            altFormat: 'm-Y',
          }),
        ],
      },
    }
  },
  computed: {
    beforeMonthText() {
      const { month, year } = getBeforeMonth()
      return `${String(month).padStart('0', 2)}/${year}`
    },
    countByStatus() {
      // if (!this.sumData || !this.sumData.count_by_status || !this.sumData.count_by_status.length) return []
      const { list } = this.$status
      const cbs = {}
      list.forEach(item => {
        cbs[item.value] = {
          ...item,
          count: 0,
        }
      })
      this.sumData.count_by_status.forEach(({
        count,
        status,
      }) => {
        cbs[status].count = count
      })
      return Object.values(cbs) || []
    },
    hosoByStatus() {
      return this.countByStatus.filter(item => item.type === 'hs') || []
    },
    hopdongByStatus() {
      return this.countByStatus.filter(item => item.type === 'hd') || []
    },
    chartData() {
      const n = this.sumDataChart
      const { getColor, getName, list } = this.$status
      const rpContract = {
        labels: [],
        series: [],
        colors: [],
      }

      n.count_by_status.forEach(s => {
        rpContract.labels.push(getName(s.status))
        rpContract.series.push(parseFloat(s.count))
        rpContract.colors.push(getColor(s.status))
      })

      list.forEach(s => {
        if (!rpContract.labels.includes(s.label)) {
          rpContract.labels.push(s.label)
          rpContract.series.push(0)
          rpContract.colors.push(s.color)
          n.count_by_status.push({
            count: '0',
            status: s.value,
          })
        }
      })
      return {
        series: rpContract.series,
        chartOptions: {
          legend: {
            show: false,
          },
          colors: rpContract.colors,
          dataLabels: {
            enabled: true,
            formatter(val) {
              // số hồ sơ
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                size: '30%',
                labels: {
                  show: true,
                  name: {
                    show: false,
                  },
                  value: {
                    show: true,
                    fontSize: '1.2rem',
                    fontFamily: 'Montserrat',
                    color: '#756bc9',
                    offsetY: 10,
                    fontWeight: 700,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`
                    },
                  },
                  total: {
                    show: true,
                  },
                },
              },
            },
          },
          labels: rpContract.labels,
          responsive: [
            {
              breakpoint: 1025,
              options: {
                chart: {
                  height: 240,
                },
              },
            },
            {
              breakpoint: 769,
              options: {
                chart: {
                  height: 200,
                },
              },
            },
            {
              breakpoint: 768,
              options: {
                chart: {
                  height: 300,
                },
              },
            },
          ],
        },
      }
    },
    tableColumnsDebtCollectionReport() {
      return [
        {
          label: 'Mã',
          key: 'code',
        },
        {
          label: 'Tên đội',
          key: 'doi',
          tdClass: 'text-nowrap',
        },
        {
          label: 'Ngày thu',
          key: 'ngay-thu',
          stickyColumn: true,
        },
        {
          label: 'Thu được',
          key: 'thu-duoc',
        },
        {
          label: 'Chi đội',
          key: 'chi-doi',
        },
        {
          label: 'Loại',
          key: 'loai-no',
          tdClass: 'text-nowrap',
        },
        {
          label: 'Phí DV',
          key: 'phi-dv',
        },
        {
          label: 'Tổng PDV',
          key: 'tong-pdv',
        },
      ]
    },
  },
  methods: {
    goContractsList(filter) {
      const from = new Date(this.timeFilter.yearStart, this.timeFilter.monthStart ? this.timeFilter.monthStart - 1 : 0, 1)
      const to = new Date(this.timeFilter.yearEnd, this.timeFilter.monthEnd ? this.timeFilter.monthEnd : 12, 1)
      const rangeDateFilter = `${moment(http.getISODate(from)).format('YYYY-MM-DD')} to ${moment(http.getISODate(to)).format('YYYY-MM-DD')}`

      this.$router.push({
        name: 'contracts-list',
        query: {
          filter: JSON.stringify({
            noAutoCompareTime: true,
            typeDateFilter: 'from_collect_at',
            rangeDateFilter,
            ...filter,
          }),
        },
      })
    },
    customJoin(array) {
      if (array.length === 0) return ''
      if (array.length === 1) return array[0]
      if (array.length === 2) return array.join(' và ')

      // Join all items with ', ' except the last one, which gets ' và '.
      return `${array.slice(0, -1).join(', ')} và ${array[array.length - 1]}`
    },
    openModalDebtDetail() {
      this.$refs['modal-debt-detail'].show()
      this.fetchDebtCollectionDetailReport(this.department_id)
    },
  },
}
</script>

<style lang="scss">
.contract-report-wrap {
  padding: 2rem;

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
}
.custom-field {
  display: flex;
  align-items: center;
  gap: 8px;
  &--label, &--value {
    font-size: 14px;
    font-weight: 600;
  }
}
.d-grid--time-report-deparment {
  display: grid;
  grid-template-columns: calc(50% - 4px) calc(50% - 4px);
  gap: 8px;
  .w-100px {
    width: 100px;
  }
  .vs--disabled {
    .vs__dropdown-toggle {
      background-color: #fff;
    }
  }
}
</style>
